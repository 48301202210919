
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&family=Roboto&display=swap');

.un span{
   
   
    font-family: 'Rajdhani', sans-serif;
    margin:  0;
    line-height: .9;
   

   
    
}
.about span{
    font-family: 'Rajdhani', sans-serif;
    margin:  0;

}
.build span{
    font-family: 'Rajdhani', sans-serif;
    margin:  0;
    line-height: .9;
}
.foot span{
    font-family: 'Rajdhani', sans-serif;
    margin:  0;
}

.box{
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);

    background: #000000;  /* fallback colour. Make sure this is just one solid colour. */
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.934), rgba(0, 0, 0, 0.94)), url(../assets/bgm9.gif);
    border-radius: 10px;
}


.nav{
    
  top: 0;
  padding: 7px;
    z-index: 1;
  position: fixed;
  
    width: 100%;
   
  min-height: 60px;
  border-bottom: 1px solid var(--white);
  transform: translateY(0rem);
  -webkit-backdrop-filter: blur(.3rem);
  backdrop-filter: blur(0.3rem);

  background-color: rgba(0,0,0,.3);
}

.un span{
    font-size: 55px;
}
.about span{
    font-size: 55px;   
}
.build span{
    font-size: 50px;
}
@media screen and (max-width: 600px) {
    .foot{
        flex-direction: column !important;
    }
    .foot2{
        width: 250px;
    }
    .con{
        flex-direction: column;
        gap: 20px;
    }
    .un span{
        font-size: 36px  ;
    }
    .un{
        top: 60% !important;
       
    }
    .about span{
        font-size: 36px;   
    }
    .build span{
        font-size: 36px;
    }
}