.App {
  text-align: center;
}



@media screen and (min-width: 960px) {
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #1111;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 8px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;

}
}